import { graphql, useStaticQuery } from 'gatsby'

const useBannersQuery = () => {
  const data = useStaticQuery(graphql`
    {
      banners: allContentfulBanner {
        nodes {
          ...BannerFragment
        }
      }
    }
  `)

  return data.banners.nodes
}

export default useBannersQuery
