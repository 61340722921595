/** @jsx jsx */
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { jsx } from 'theme-ui'
import ContentSection from '~/components/Content/Section'

const ContentPage = ({ page, isTrade = false }) => {
  return (
    <Fragment>
      {page.sections &&
        page.sections.map((section) => {
          return (
            <ContentSection
              key={section.id}
              section={section}
              isTrade={isTrade}
            />
          )
        })}
    </Fragment>
  )
}

ContentPage.propTypes = {
  page: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
}

export default ContentPage
