import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ContentPage from '~/components/Content/Page'
import useBanners from '~/hooks/components/use-banners'

const StaticPageTemplate = ({ data }) => {
  const { page } = data
  const { slug } = page

  const banner = useBanners(slug)

  return (
    <Layout banner={banner}>
      <Metadata title={page.name} noindex={page.noindex} />
      <ContentPage page={page} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query StaticPageQuery($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`

export default StaticPageTemplate
