import useBannersQuery from '~/hooks/graphql/queries/use-banners'

const useBanners = (slug) => {
  const banners = useBannersQuery()

  return banners?.find(
    (banner) =>
      banner?.placement?.filter((placement) => placement.slug === slug).length >
      0
  )
}

export default useBanners
